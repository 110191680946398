<!--
 * @Author       : JiangChao
 * @Date         : 2023-03-19 20:51:44
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-04-02 09:41:32
 * @Description  : 退還押金
-->
<template>
    <div>
        <div class="bg" :style="{ minHeight: boxheight }">
            <div class="title1"><span>退還押金</span></div>
            <p>租借前需支付押金，退還行動電源後可退押金</p>

            <div class="card">
                <span>押金</span>
                <span>NT$ 420</span>
                <van-image
                    width="40px"
                    height="50px"
                    style="margin-left: 10px"
                    :src="iconContentYajin"
                />
            </div>
            <div class="login-way">
                <van-button
                    class="sms-way"
                    style="width: 200px"
                    @click="confirm"
                    :disabled="isDisabled"
                    >{{ tip }}</van-button
                >
            </div>
            <div class="box">
                <div>
                    <div class="line"></div>
                    <div class="line1">
                        <van-image
                            width="20px"
                            class="image"
                            height="20px"
                            :src="defaultIcon"
                        />
                        <span :style="step1">退款申請已發出</span>
                    </div>
                    <div class="line1">
                        <van-image
                            width="20px"
                            height="20px"
                            :src="defaultIcon2"
                        />
                        <span :style="step2">退款進行中</span>
                    </div>
                    <div class="line1">
                        <van-image
                            width="20px"
                            height="20px"
                            :src="defaultIcon3"
                        />
                        <span :style="step3">退款已完成</span>
                    </div>
                </div>
            </div>

            <div>退還須知</div>
            <div>
                押金在七日內可退還原支付帳號，部分支付方式支持秒退押金及免手續費，信用卡需支付部分手續費
            </div>
            <div style="margin-top: 20px">如有問題，請聯繫:</div>
            <div>pluginn.info@gmail.com</div>
        </div>
        <div class="auth-container">
            <div class="lds-ring"></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div
                v-if="showAlert"
                class="light-box flex-container flex-center center flex-center-h"
            >
                <div class="box flex-container flex-column flex-center center">
                    <span class="text-1500 text-b title">提醒</span>
                    <span class="text-1250">是否確認退款</span>
                    <button
                        type="button"
                        class="capsule-button text-875 capsule-small check"
                        @click="leasing()"
                    >
                        確認
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
import { handleErr } from "@/helpers";

export default {
    name: "",
    data() {
        return {
            iconContentYajin: require("@/assets/imgs/png/icon_content_yajin.png"),
            defaultIcon: require("@/assets/imgs/png/icon_login_default.png"),
            defaultIcon2: require("@/assets/imgs/png/icon_login_default.png"),
            defaultIcon3: require("@/assets/imgs/png/icon_login_default.png"),
            step1: {
                color: "",
            },
            step2: {
                color: "",
            },
            step3: {
                color: "",
            },
            showAlert: false,
            tip: "退還押金",
            isDisabled: false,
            userId: "",
            timer: null,
        };
    },
    methods: {
        async initMode() {
            const res = await this.$axios.get("tw/checkDes");
            if (res.data.success) {
                switch (res.data.result.key) {
                    case 1:
                        //都不勾
                        break;
                    // case 2:
                    //     this.defaultIcon = require("@/assets/imgs/png/icon_agree_selected.png");
                    //     this.defaultIcon2 = require("@/assets/imgs/png/icon_agree_selected.png");
                    //     this.step1.color = "#333333";
                    //     this.step2.color = "#333333";
                    //     break;
                    case 3:
                        this.defaultIcon = require("@/assets/imgs/png/icon_agree_selected.png");
                        this.defaultIcon2 = require("@/assets/imgs/png/icon_agree_selected.png");
                        this.defaultIcon3 = require("@/assets/imgs/png/icon_agree_selected.png");
                        this.step1.color = "#333333";
                        this.step2.color = "#333333";
                        this.step2.color = "#333333";
                        break;
                }
            }
        },
        async confirm() {
            this.showAlert = true;
        },
        async leasing() {
            this.showAlert = false;
            const res = await this.$axios.get(
                `tw/refund?userId=${this.userId}`
            );
            if (res.data.success) {
                this.tip = "NT$ 0";
                Toast("退還成功");
                this.isDisabled = false;
                this.initMode();
                this.timer = setTimeout(() => {
                    this.$router.push("/map");
                }, 2000);
            } else {
                Toast(res.data.msg);
            }
        },
        async getUserInfo() {
            try {
                const res = await this.$axios.get("customer/user-info");
                console.log("res: ", res);
                if (res.data.success) {
                    this.userId = res.data.result.id;
                }
            } catch (error) {
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    props: {},
    components: {},
    computed: {
        boxheight() {
            return `${window.innerHeight}px`;
        },
    },
    mounted() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
        this.initMode();
        this.getUserInfo();
    },
    created() {},
    destroyed() {
        clearTimeout(this.timer);
    },
};
</script>

<style scoped lang="less">
.bg {
    background: linear-gradient(180deg, #f9e266, #eda143);
    padding: 10px 20px 20px 20px;
}
.title1 {
    background: #fee26c;
    text-align: center;
    padding: 10px;
}
.card {
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    span {
        font-size: 20px;
    }
}
.login-way {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .sms-way {
        background: #2c394e;
        border: 0px;
        border-radius: 25px;
        height: 50px;
        color: #ffffff;
        line-height: 50px;
        margin: 20px;
        text-align: center;
    }
}
.step-bg {
    background: transparent;
}
.van-hairline {
    border-bottom: red;
}
.line1 {
    display: flex;
    padding: 5px;
    span {
        margin-left: 10px;
    }
}
.line2 {
    display: flex;
    padding: 5px;
    span {
        margin-left: 10px;
    }
}
.line3 {
    display: flex;
    padding: 5px;
    span {
        margin-left: 10px;
    }
}
.box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 150px;
    margin-top: 50px;
}

.light-box {
    position: absolute;
    top: 0px;
    z-index: 30;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);

    .box {
        width: 280px;
        background-color: white;
        border-radius: 8px;
        -webkit-border-radius: 8px;

        .title {
            padding-top: 24px;
            padding-bottom: 12px;
        }

        .msg {
            background-color: #eeeeee;
            padding: 36px 56px;
        }

        .image {
            width: 100%;
            max-height: 60vh;
            object-fit: contain;
        }

        .check {
            margin-top: 16px;
            margin-bottom: 24px;
        }
    }
}

.flex-container {
    display: flex;
}
.flex-center {
    align-items: center;
}
.center {
    text-align: center;
}
.flex-center-h {
    justify-content: center;
}
.flex-column {
    flex-direction: column;
}

.text-1500 {
    font-size: 1.5rem;
    color: #444444;
}
.text-b {
    font-weight: bold;
}
.text-1500 {
    font-size: 1.5rem;
    color: #444444;
}

.title {
    width: 100%;
}
</style>
